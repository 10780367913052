<template>
  <div class="p-d-flex p-jc-center img-background"></div>
</template>
<script>
export default {
  layout: 'Public',
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$store.commit('oauth/clearSession')
        this.$router.push({ path: '/' })
      }, 3000)
    })
  }
}
</script>

<style>
.img-background {
  /* The image used */
  background-image: url('../assets/images/aging-thanks.png');
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>